html body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  /* Apply focus styles only when navigating with the keyboard */
  :focus:not(:focus-visible) {
    outline: none;
  }
}

[data-theme-mode='dark'] {
  body {
    background-color: #212121;
  }
}

// UA styles https://github.com/PlayQ/tg-octopus/wiki/UA-styles
button {
  background-color: transparent;
}

#vg-tooltip-element {
  font-size: 12px;
}

.dialogContentHeight80 {
  .MuiDialogContent-root {
    height: 80vh;
    padding: 0;
  }
}

.disabled-link {
  pointer-events: none;
}
